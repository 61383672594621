import * as React from "react"
import Layout from "../components/layout"


// markup
export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          <h1>Olá! Eu chamo-me Filipe Correia</h1>
          <h2>Como te chamas?</h2>
          <form>
          Aqui deve estar um formulário com id = "name" -&gt; que é armazenado nos cookies  e / ou LocalStorage API .
          </form>
          <p>Isto é um parágrafo!</p>
        </div>
      </section>
    </Layout>
  )
}
