import React from "react"
import { Link } from "gatsby"
import "./layout.css"

// Links do Menu

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem`, paddingTop: "12px" }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)




export default function Layout({ children }) {
  return (
  <div>
  <header style={{ paddingTop: 20 }}>
    <Link to="/" style={{ display: "inline-block", paddingTop: 10, paddingBottom: 10, paddingRight: 20, paddingLeft: 50, backgroundColor: "#2916ff" }}>
      <h3 style={{color: "white"}}>FMCorreia</h3>
    </Link>  
        <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </header>
  <div>
      {children}
  </div>
  </div>
  )
}